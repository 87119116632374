import React, { useState, useEffect } from "react";
import { graphql, withPrefix, Link } from "gatsby";
import Layout from "../layouts/index";

const GetEncodedData = () =>{
  const json_data = decodeURIComponent(escape(window.atob(window.location.hash.substr(1))));
  if (json_data) {
    return JSON.parse(json_data);
  } else {
    return {"email": "", hash: ""};
  }
};

const Home = (props) => {
  const [encodedData, setEncodedData] = useState({"email": "", hash: ""});
  useEffect(() => {   setEncodedData(GetEncodedData());});
  return (
    <Layout bodyClass="page-home">
      <div className="intro pb-4">
        <div className="container">
          <h1>Vielen Dank!</h1>
          <p>
            Ihre E-Mail Addresse {encodedData.email} ist bestätigt.
            Wir melden uns bei Ihnen, sobald der nächste Fleischverkaufstermin feststeht.
          </p>
          <p>
            Falls Sie ein anderes Anliegen haben, können Sie uns erreichen unter <a href="mailto:direktvermarktung@jolhof.de">direktvermarktung@jolhof.de</a>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
